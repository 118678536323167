.search-bar {
  width: 90% !important;
  border-radius: 20px !important;
  left: 30%;
  position: relative;
  top: 5px;
  height: 40px;

  input {
    font-size: 16px !important;
    margin-left: 10px;
  }
}

.search-icon {
  width: 40px;
  margin-left: -11px;
  background-color: #befc95;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;

  :hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 1400px) {
  .search-bar {
    left: 20%;
    width: 70% !important;
  }
}

@media screen and (max-width: 1200px) {
  .search-bar {
    left: 0%;
    width: 60% !important;

    input {
      font-size: 13px !important;
      margin-left: 10px;
    }
  }
}
