.product-category-tabs {
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  height: 8vh;
  padding: 0px 150px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
}

.product-category-tab {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
  min-width: 100px;
  text-wrap: wrap;
  outline: none;
}

button:hover {
  color: black;
}

/* Style for the active tab */
.product-category-tab.active {
  color: #347752;
}

/* Style for the tab underline */
.tab-underline {
  height: 3px;
  width: 100%;
  background-color: #347752;
  transition: width 0.2s ease-in-out;
}

@media screen and (max-width: 1200px) {
  .product-category-tabs {
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 20px 20px;
    top: 150px;
    flex: 100%;
    padding: 20px 0px;
  }

  .product-category-tab {
    min-width: 100px;
    padding: 10px 0px;
    font-size: 13px;
  }
}
