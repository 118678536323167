.terms-of-service-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 300px 30px 300px;

  ul {
    list-style-type: none;
  }
}

.tos-content {
  text-align: left;
}

.tos-list {
  margin-top: 30px;
  h3 {
    margin-bottom: 20px;
  }

  div {
    text-align: left;
  }
}
