.dropdown-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  position: relative;
  left: 10%;
}

.language-picker-dropdown {
  &--dropdown {
    text-align: right;
  }

  button {
    background-color: transparent;
    font-size: 1rem;
    opacity: 100%;
    border: 1px solid #c6c6c6;

    &:hover {
      background-color: transparent;
    }

    .dropdown-menu {
      min-width: 1rem;
    }

    .dropdown-toggle::after {
      display: none;
    }
  }
}

@media screen and (max-width: 1200px) {
  .language-picker-dropdown {
    button,
    a {
      font-size: 13px;
    }
  }
}
