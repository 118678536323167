.navbar-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
  height: 70px;
  border-radius: 3px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin-bottom: 50px;
  z-index: 3;
  background-color: #ffffff;
  border-bottom: 1px solid #dfdfdf;
  border-radius: 5px;
}

.navbar-logo {
  color: #222;
  font-size: 2rem;
  cursor: pointer;
}

.kupon-logo {
  margin: 7px 0px 0px 30px;
  height: 70px;
  width: 150px;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(9, auto);
  grid-gap: 10px;
  list-style: none;
  align-items: center;
  position: relative;
  top: 10%;
}

.nav-links {
  text-decoration: none;
  color: #222;
  font-size: 1.2em;
  font-weight: 600;
  padding: 0.7rem 1rem;
  white-space: nowrap;
}

.nav-links i {
  padding-right: 10px;
}

.nav-links:hover {
  background-color: #01959a;
  color: #fff;
  border-radius: 4px;
  transition: all 0.2 ease-in-out;
}

.nav-links-mobile,
.menu-icons {
  display: none;
}

.login-signup-button {
  padding: 0.5rem 1rem;
  white-space: nowrap;
  border-radius: 0.3rem;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  background-color: #e5e7eb;
  transition: 0.2s ease-in-out;
  &:hover {
    color: #fff;
  }
}

.button-container {
  cursor: pointer;
  border-radius: 0.3rem;
  background-color: #e5e7eb;
  display: flex;
  flex-direction: row;
  padding: 0px 10px;
}

.user-icon {
  padding-top: 8px;
}

.user-info-container {
  display: flex;
  flex-direction: row;
}

.name-circle {
  position: relative;
  top: 2px;
  width: 40px;
  margin-right: 20px;
  border: 1px solid #befc95;
  border-radius: 20px;
  background-color: #befc95;

  span {
    position: relative;
    top: 3px;
    font-size: 20px;
    font-weight: 700;
  }
}

.logout-icon {
  position: relative;
  top: 8px;
}

.navbar-mobile-container {
  display: flex;
  flex-direction: column;
  height: 15vh;
  background-color: #ffffff;
  z-index: 3;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  width: 100%;
}

.navbar-mobile-first-container {
  display: flex;
  flex-direction: row;
}

.navbar-mobile-item {
  z-index: 2;
  position: relative;
  background-color: #ffffff;
}

.mobile-logo {
  width: 100%;
  height: 12vh;
  position: fixed;
  top: 0px;
  left: -30%;
}

.mobile-language-dropdown {
  position: fixed;
  top: 10px;
  left: 45%;
  z-index: 3;
  width: 30%;
  height: 10vh;
}

.mobile-user-profile-container {
  position: fixed;
  top: 25px;
  left: 85%;
  z-index: 3;
}

.mobile-search-bar {
  font-size: 13px;
  position: fixed;
  top: 100px;
  left: -10%;
}

.mobile-button-container {
  cursor: pointer;
  border-radius: 0.3rem;
  width: 80%;
  padding: 10px 0px;
}

.login-signup-button {
  padding: 0.5rem 1rem;
  white-space: nowrap;
  border-radius: 0.3rem;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  &:hover {
    color: #fff;
  }
}
