.footer {
  background-color: #e9e8e8;
  padding: 4rem 6rem;
}

.top {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: start;
  justify-content: space-between;
}

.top-message {
  flex-grow: 1;
  flex-basis: 1;
  width: 35%;
}

.top-connect {
  flex-grow: 1;
  flex-basis: 1;
  width: 65%;
  text-align: right;
}

.top i {
  color: #aaa;
  font-size: 2rem;
  margin-left: 1rem;
}

.top i:hover {
  color: #01959a;
}

.top .connect-us {
  margin: 0 0 10px 15px;
}

.bottom {
  padding-top: 2rem;
  text-align: start;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bottom div {
  display: flex;
  flex-direction: column;
}

.bottom h4 {
  font-size: 1.3rem;
  padding: 1rem 0 0.8rem 0;
}

.bottom a {
  text-decoration: none;
  color: #000;
  padding-bottom: 0.4rem;
  font-size: 1.1rem;
}

@media screen and (max-width: 1200px) {
  .footer {
    padding: 4rem 2rem;
    font-size: 12px;
    height: 50%;
  }

  .footer i {
    margin: 1rem 1rem 0 0;
  }

  .bottom div {
    width: 50%;
    a {
      font-size: 13px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .top i {
    font-size: 18px;
  }

  .top p {
    font-size: 12px;
  }

  .top h3 {
    font-size: 20px;
  }

  .footer-help,
  .footer-more {
    position: relative;
    left: 25%;
  }
}
