.products-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
  grid-column-gap: 20px;
  justify-items: center;
  list-style: none;
  margin-top: 175px;
  padding: 30px 100px 30px 100px;
  background-color: #f6f7f6;
  border-radius: 13px;
}

.product-item {
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 250px;
  max-height: 100%;
  max-height: 100%;
  padding: 10px;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 13px;
  filter: drop-shadow(rgb(238, 235, 235) 5px 5px 3px);
}

.product-title {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
  white-space: nowrap;
  padding-top: 10px;
  padding-right: 20px;
}

.product-title:hover {
  cursor: pointer;
  text-overflow: unset;
  white-space: wrap;
}

img {
  width: 90%; /* or any custom size */
  height: 150px;
  object-fit: contain;
}

.product-details {
  grid-template-columns: repeat(3, auto);
}

.product-price {
  margin: 5px 0;
  min-height: 20px;
}

.product-detail-price {
  float: left;
}

.product-origin-price {
  text-decoration: line-through;
  float: left;
  margin-left: 10px;
  font-weight: 100;
}

.discount-percentage {
  float: right;
  margin-right: 30px;
  background-color: #befc95;
  width: 80px;
  height: 20px;
  border-radius: 5px;
  font-weight: 600;
}

.product-comment {
  float: left;
  margin: 5px 0;
}

.product-rating-star {
  position: relative;
  top: 1px;
  margin-right: 5px;
}

.product-rating-count {
  font-weight: 300;
  color: #8f8f8f;
}

@media screen and (max-width: 1600px) {
  .products-container {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (max-width: 1300px) {
  .products-container {
    grid-template-columns: repeat(4, 1fr);
    padding: 30px 10px 0px 10px;
  }
}

@media screen and (max-width: 1000px) {
  .products-container {
    grid-template-columns: repeat(3, 1fr);
    z-index: -1 !important;
    padding: 30px 0px;
    margin-top: 200px;
    grid-column-gap: 5px;
  }
  .product-item {
    width: 100%;
    max-height: none;
    margin-bottom: 5px;
  }
  .product-title {
    width: 180px;
    font-size: 13px;
    text-overflow: ellipsis;
  }
  .product-detail-price,
  .product-origin-price,
  .discount-percentage,
  .product-comment {
    font-size: 13px;
  }
  .discount-percentage {
    width: 35%;
    margin-right: 0px;
  }
}

@media screen and (max-width: 800px) {
  .products-container {
    grid-template-columns: repeat(2, 45vw);
    column-gap: 10px;
    z-index: -1 !important;
    opacity: 1;
    margin-left: 15px;
  }
}
