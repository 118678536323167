.about-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 150px 250px 30px 250px;
  min-height: 55vh;
}

.about-content {
  text-align: left;
  margin: 20px 50px 0 50px;

  font-size: 20px;
}
