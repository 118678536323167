.product-page-detail-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  margin-top: 70px;
  padding: 50px 0px 30px 0px;
  background-color: #fff;
  border-radius: 13px;
  min-height: 55vh;
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
  background-image: linear-gradient(120deg, #e9defa 0%, #fbfcdb 100%);
}

.product-page-container {
  margin-left: 200px;
}

.product-detail-title {
  font-size: 25px;
  font-weight: 700;
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
}

.price-container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
}

.price {
  float: left;
  font-size: 25px;
  font-weight: 700;
}

.list-price {
  text-decoration: line-through;
  float: left;
}

.rating-title {
  margin-left: 20px;
  font-size: 18px;
  font-weight: 600;
  width: 5%;
  text-align: left;
}

.rating {
  width: 20%;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  width: 7%;
}

.rating-count {
  width: 7%;
  text-align: left;
  span {
    font-size: 18px;
    font-weight: 700;
    margin-right: 10px;
  }
}

.product-code-expire-time {
  font-size: 18px;
  width: 30%;
  text-align: left;
  padding-left: 10px;
  background-color: #b3ffab;
  border-radius: 10px;

  span {
    margin-left: 10px;
  }
}

.coupon-code-container {
  width: 100%;
  height: 100px;
  background-image: linear-gradient(120deg, #b3ffab 0%, #12fff7 100%);
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.product-discount {
  position: relative;
  top: 30%;
  margin-left: 50px;
  font-size: 30px;
  font-weight: 700;
}

.product-coupon-code-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
  width: 12vw;
  margin: 20px 30px;
  border-radius: 10px;
  background-color: #f2f6ff;
  cursor: pointer;

  span {
    position: relative;
    top: 30%;
    font-size: 20px;
    font-weight: 700;
    margin-right: 30px;
  }
}

.product-coupon-icon {
  position: relative;
  top: 25%;
  left: 10%;
}

.product-page-details {
  display: flex;
  gap: 40px;
  flex-direction: row;
  justify-content: space-between;
  gap: 50px;
  margin-top: 30px;
}

.product-page-url {
  cursor: pointer;
  width: 50%;
  height: 40px;
  position: relative;
  float: right;
  margin-top: 30px;
  border-radius: 10px;
  text-decoration: underline;
  font-size: 18px;
  font-weight: 700;
}

.product-image {
  width: 100%;
  height: 500px;
  border-radius: 20px;
  overflow: hidden;
  padding: 10px 0;
  background-color: white;
}

.product-image img {
  flex-shrink: 1;
  width: auto;
  height: 100%;
  object-fit: cover;
}

.product-description {
  width: 100%;
  height: auto;
  border-radius: 20px;
  background-color: white;

  ul {
    margin: 0 40px;
  }

  li {
    font-size: 18px;
    text-align: left;
    margin-bottom: 10px;
  }
}

.product-description-title,
.product-top-review-title {
  font-size: 25px;
  font-weight: 700;
  width: 100%;
  text-align: left;
  margin: 20px 0 20px 20px;
}

.product-top-review-and-other {
  width: 100%;
  border-radius: 20px;
  display: flex;
}

.product-other {
  flex-grow: 1;
  flex-basis: 0;
}

.product-top-review {
  flex-basis: 0;
  flex-grow: 0.93;
  margin-top: 10px;
  padding-top: 5px;
  padding-left: 5px;
  border-radius: 20px;
  background-color: white;

  ul {
    margin: 0 40px;
  }

  li {
    font-size: 18px;
    text-align: left;
    margin-bottom: 10px;
    padding-bottom: 25px;
  }
}

.mobile-product-page-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 13px;
  height: auto;
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
  background-image: linear-gradient(120deg, #f3f3f7 0%, #f7f7f5 100%);
}

.mobile-product-detail-title {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  text-align: left;
  position: relative;
  top: 170px;
  padding: 5px 10px;
  height: 20vh;
}

.mobile-price-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 19vh;
  position: relative;
  top: 120px;
  padding: 5px 10px;
}

.mobile-price {
  float: left;
  font-size: 13px;
  font-weight: 700;
}

.mobile-list-price {
  font-size: 13px;
  margin-left: 10px;
  text-decoration: line-through;
  float: left;
}

.mobile-rating {
  margin-left: 2vw;
  width: 20vw;
  font-size: 13px;
  font-weight: 600;
  text-align: right;
}

.mobile-rating-count {
  margin-left: 5px;
  position: relative;
  top: -2px;
  font-weight: 400;
  width: 30vw;
  text-align: left;
}

.mobile-rating-text {
  font-size: 14px;
  font-weight: 400;
}

.mobile-product-discount {
  position: relative;
  margin-left: 2vw;
  width: 20vw;
  text-align: center;
  background-color: #9bf3a0;
  height: 3vh;
  border-radius: 4px;
  padding-top: 2px;
  font-size: 13px;
  font-weight: 700;
}

.mobile-product-code-expire-time-container {
  width: 100%;
}

.mobile-product-code-expire-time {
  text-align: left;
  margin: 5px 0px 20px 0px;
  font-size: 14px;
  background-color: #b3ffab;
  padding: 2px 10px;
  border-radius: 10px;
  span {
    margin-left: 10px;
  }
}

.mobile-product-image {
  width: 95%;
  height: 40vh;
  border-radius: 20px;
  overflow: hidden;
  padding: 10px 0;
  background-color: white;
}

.mobile-product-image img {
  flex-shrink: 1;
  width: auto;
  height: 100%;
  object-fit: cover;
}

.mobile-product-description,
.mobile-product-top-review {
  position: relative;
  top: 0vh;
  width: 100%;
  border-radius: 20px;

  .mobile-description-list {
    margin: 0 10px;
  }

  li {
    font-size: 13px;
    text-align: left;
    margin-bottom: 10px;
  }
}

.mobile-product-description-title,
.mobile-product-top-review-title {
  font-size: 17px;
  font-weight: 700;
  width: 100%;
  text-align: left;
  margin: 20px 0 20px 20px;
}

.mobile-product-page-url {
  cursor: pointer;
  width: 50%;
  height: 40px;
  position: relative;
  float: right;
  margin-top: 10px;
  border-radius: 10px;
  text-decoration: underline;
  font-size: 13px;
  font-weight: 700;
}

.mobile-coupon-code-container {
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 9vh;
  border-radius: 10px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.mobile-product-coupon-code-btn {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  height: 6vh;
  width: 35%;
  margin: 10px 20px;
  border-radius: 10px;
  background-color: #befc95;
  cursor: pointer;

  span {
    position: relative;
    top: 30%;
    font-size: 15px;
    font-weight: 700;
    margin-left: 35px;
    color: #1c1c1c;
  }
}
