.chatbox-container {
  height: 100%;
  position: relative;
  height: 80vh;
  transform: rotateY(0);
  max-width: 80%;
  width: 50%;
}

.cs-main-container {
  border-radius: 20px 0 0 20px;
}
