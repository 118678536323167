.coupon-code-detail {
  height: 250px;
  text-align: center;
  line-height: 250px;
}

.coupon-code-emoji {
  font-size: 50px;
  height: 40px;
  line-height: 40px;
}

.coupon-code-saving-header {
  line-height: 60px;
  font-size: 25px;
  font-weight: 600;
}

.coupon-code-saving-content {
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
}

.coupon-code-columns-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.coupon-code-btn {
  margin-top: 20px;
  line-height: 60px;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
  }
}

.coupon-code {
  width: 70%;
  background-color: #f6f6f6;
}

.coupon-code-verify,
.coupon-code-copy {
  width: 30%;
  color: rgb(52, 119, 82);
  background-color: rgb(244, 252, 242);
  font-size: 20px;
}

.copy-code-btn {
  margin-left: 40px;
}

.coupon-code-button {
  font-size: 18px !important;
  font-weight: 400 !important;
  span {
    left: 38%;
  }
}

.mobile-coupon-code-detail {
  position: relative;
  top: 15vh;
  height: 25vh;
  text-align: center;
  line-height: 25vh;
  padding-right: 10px;
}

.mobile-coupon-code-emoji {
  font-size: 30px;
  height: 40px;
  line-height: 40px;
}

.mobile-coupon-code-saving-header {
  line-height: 7vh;
  font-size: 18px;
  font-weight: 600;
}

.mobile-coupon-code-saving-content {
  font-size: 15px;
  line-height: 3vh;
  font-weight: 300;
  padding: 0px 20px;
}

.mobile-coupon-code-columns-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.mobile-coupon-code-btn {
  margin-top: 20px;
  height: 5vh;
  line-height: 5vh;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
  }
}

.mobile-coupon-code-copy {
  width: 30%;
  color: rgb(52, 119, 82);
  background-color: rgb(244, 252, 242);
  font-size: 14px;
}

.mobile-coupon-back-button-container {
  position: relative;
  top: 15vh;
  padding-right: 10px;
}

.mobile-coupon-code-button {
  font-size: 14px !important;
  font-weight: 400 !important;
  span {
    left: 25%;
  }
}
